<template>
    <div id="ErpIntegrationRun">
        <v-container fluid app>
            <v-layout v-resize="onResize">
                <v-data-table
                    :headers="apiResult.headers"
                    :items="apiResult.items"
                    :items-per-page="30"
                    :footer-props="footerProps"
                    :page.sync="pagination.page"
                    @pagination="updatePage"
                    :item-key="getItemKey()"
                    :search="search"
                    :loading="loading"
                    loading-text="Carregando dados..."
                    class="elevation-1 text-no-wrap"
                    :height="noData ? '' : windowSize.y - 64 - 157"
                    fixed-header
                    style="width: 100%;"
                    :show-expand="true"
                    :single-expand="false"
                    :expanded.sync="expanded"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="rounded-lg">
                            <div class="d-flex align-start mr-4">
                                <v-icon x-large color="primary">
                                    {{ tableIcon }}
                                </v-icon>
                            </div>
                            <div class="d-flex flex-column align-start">
                                <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                            </div>

                            <div class="d-flex align-center ml-8">
                                <img src="../../public/img/znap-app-bar.png" alt="Logomarca netsuite"
                                    style="width: 92px;"
                                >

                                <v-icon class="mx-4" color="black">mdi-arrow-right-thick</v-icon>
                                
                                <img src="../../public/img/netsuite-logo-img.png" alt="Logomarca netsuite"
                                    style="width: 92px;"
                                >
                            </div>

                            <v-spacer></v-spacer>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                hide-details
                                single-line
                                dense
                                clearable
                                class="mr-4"
                                style="width: 1%;"
                            />

                            <v-tooltip top v-if="selectedFathers.length || selectedChilds.length">
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        v-on="on"
                                        class="secondary mb-2 mr-4 pa-0"
                                        min-width="48px"
                                        @click="runIntegrations()"
                                        :disabled="disableRunIntegrations"
                                        :loading="disableRunIntegrations"
                                    >
                                        <v-icon> {{ disableRunIntegrations ? 'mdi-sync-off' : 'mdi-sync' }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>Processar integrações</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        color="primary"
                                        v-on="on"
                                        class="mb-2 mr-4 pa-0"
                                        min-width="48px"
                                        @click="refresh()"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Atualizar</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        color="primary" dark
                                        v-on="on"
                                        class="mb-2 pa-0"
                                        min-width="48px"
                                        @click="closeTable()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>

                            <v-dialog v-model="dialogCloseTable" max-width="600px">
                                <v-card>
                                    <div class="d-flex justify-center">
                                        <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                                    </div>
                                    <v-card-actions class="px-6 pb-6">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                                        <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>

                        <v-divider class="mx-4 mb-2"></v-divider>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <tr v-for="(child) in item.childs" :key="child.id_accounting_closing_type"
                        >
                            <td class="d-block d-sm-table-cell"></td>
                            <td class="d-block d-sm-table-cell d-flex justify-center">
                                <v-simple-checkbox
                                    :value="isRunningChild(child) ? true : isSelectedChild(child)"
                                    @input="selectChild(child, $event)"
                                    :ripple="false"
                                    color="secondary"
                                    style="width: 1%;"
                                    :disabled="isRunningChild(child)"
                                ></v-simple-checkbox>
                            </td>
                            <td class="d-block d-sm-table-cell">
                                <div class="d-flex justify-center">
                                    <span>{{ child.id_accounting_closing_type }}</span>
                                </div>
                            </td>
                            <td class="d-block d-sm-table-cell">
                                <div class="d-flex">
                                    <span class="mr-4">{{ child.accounting_closing_type }}</span>
                                    <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                            <v-btn small
                                                v-on="on"
                                                @click="runChild(child)"
                                                :loading="isRunningChild(child)"
                                                :disabled="isRunningChild(child)"
                                                class="primary"
                                            >
                                                <v-icon small>mdi-sync</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Processar integração</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="d-block d-sm-table-cell"></td>
                            <td class="d-block d-sm-table-cell"></td>
                            <td class="d-block d-sm-table-cell"></td>
                        </tr>
                    </template>

                    <!-- eslint-disable-next-line -->
                    <template v-slot:item="{ item, index }">
                        <tr>
                            <td>
                                <v-btn v-if="item.childs.length"
                                    icon
                                    @click="toogleExpand(item)"
                                >
                                    <v-icon>{{ isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>

                                <v-btn v-else
                                    icon
                                    disabled
                                >
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </td>

                            <template v-for="(header, headerIndex) in apiResult.headers">
                                <td v-if="header.value === 'checkbox'" :key="headerIndex" 
                                    class="d-block d-sm-table-cell d-flex justify-center"
                                    style="width: 1%;"
                                >
                                    <v-simple-checkbox
                                        :value="isRunningFather(item) ? true : isSelectedFather(item)"
                                        @input="selectFather(item, $event)"
                                        :ripple="false"
                                        color="secondary"
                                        style="width: 1%;"
                                        :disabled="isRunningFather(item)"
                                    ></v-simple-checkbox>
                                </td>

                                <td v-else :key="headerIndex" 
                                    class="d-block d-sm-table-cell"
                                >
                                    <div v-if="header.value === 'actions'" class="d-flex justify-start">
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-btn small
                                                    v-on="on"
                                                    @click="runFather(item)"
                                                    :loading="isRunningFather(item)"
                                                    :disabled="isRunningFather(item)"
                                                    class="primary"
                                                >
                                                    <v-icon small>mdi-sync</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Processar integração</span>
                                        </v-tooltip>
                                    </div>

                                    <div v-else-if="header.align === 'center'" class="d-flex justify-center">
                                        <span>{{ item[header.value] }}</span>
                                    </div>

                                    <div v-else class="d-flex justify-start">
                                        <span>{{ item[header.value] }}</span>
                                    </div>
                                </td>
                            </template> 
                        </tr>
                    </template>
                
                    <template v-slot:no-data>
                        <span class="text-h6 primary--text">{{ noDataMessage }}</span>
                    </template>
                </v-data-table>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'ErpIntegrationRun',

    computed: {
        ...mapGetters('auth', ['getHash', 'getMenu']),

        endpoint() {
            return [ this.$ipIntegration, 'erp-integration' ]
        },

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        formattedMonthYear() {
            return moment.utc(this.picker).format("MM/YYYY")
        },
    },

    data() {
        return {
            loading: false,
            disableRunIntegrations: true,
            runningIntegrations: true,

            tableRows: 10000,

            search: '',

            expanded: [],
            selectedFathers: [],
            runningFathers: [],
            selectedChilds: [],
            runningChilds: [],

            apiResult: {
                items: [],
                headers: [],
                columns: [],
            },

            pagination: {},
            backupPage: 1,
            footerProps: {
                itemsPerPageOptions: [5, 15, 30, 50],
                itemsPerPageAllText: 'Todos'
            },

            dialogCloseTable: false,
            unsavedChanges: false,

            noDataMessage: '',
            noData: true,

            windowSize: { x: 0, y: 0 },
            timeout: null,

            menu: false,
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),

            createPermission: true,
        }
    },

    created() {
        let routePath = this.$route.path
        if (this.$route.path === '/erp-integration') {
            routePath = '/erp-integration-run'
        }

        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }
        this.load()
    },

    beforeDestroy() {
        clearTimeout(this.timeout)
    },

    methods: {
        toogleExpand(item) {
            const index = this.expanded.findIndex(i => i[this.getItemKey()] === item[this.getItemKey()])

            if (index > -1) {
                this.expanded.splice(index, 1)
            } else {
                this.expanded.push(item)
            }
        },

        isExpanded(item) {
            return !!this.expanded.find(i => i[this.getItemKey()] === item[this.getItemKey()])
        },

        selectFather(item, e) {
            if (e) {
                this.selectedFathers.push(item)
                item.childs.forEach(child => {
                    let childIndex = this.selectedChilds.findIndex(i => i.id_accounting_closing_type === child.id_accounting_closing_type)
                    if (childIndex < 0) {
                        this.selectedChilds.push(child)
                    }
                })
            } else {
                const fatherIndex = this.selectedFathers.findIndex(i => i.id_erp_integration === item.id_erp_integration)
                if (fatherIndex > -1) {
                    item.childs.forEach(child => {
                        let childIndex = this.selectedChilds.findIndex(i => i.id_accounting_closing_type === child.id_accounting_closing_type)
                        if (childIndex > -1) this.selectedChilds.splice(childIndex, 1)
                    })
                    this.selectedFathers.splice(fatherIndex, 1)
                }
            }
        },

        async runFather(item) {
            const currentDate = moment(new Date()).format("YYYY-MM")
            const currentMonth = parseInt(currentDate.substr(5, 6), 10)
            const currentYear = parseInt(currentDate.substr(0, 4), 10)

            let payload = {
                filter: { conditions: [] },
                items: [
                    {
                        ...item,
                        processing_month: currentMonth,
                        processing_year: currentYear,
                        processing_status: 'NEW',
                        id_accounting_closing_type: null,
                    }
                ]
            }

            try {
                const res = await this.$http.post(`${this.$ipIntegration}erp-integration-run/mass-create`, payload)
                if (res) {
                    this.getIntegrationStatus()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        isSelectedFather(item) {
            return !!this.selectedFathers.find(i => i.id_erp_integration === item.id_erp_integration)
        },

        isRunningFather(item) {
            return !!this.runningChilds.find(i => i.id_erp_integration === item.id_erp_integration) ||
                !!this.runningFathers.find(i => i.id_erp_integration === item.id_erp_integration)
        },

        selectChild(item, e) {
            if (e) {
                this.selectedChilds.push(item)
            } else {
                const childIndex = this.selectedChilds.findIndex(i => i.id_accounting_closing_type === item.id_accounting_closing_type)
                if (childIndex > -1) {
                    this.selectedChilds.splice(childIndex, 1)
                }
            }
        },

        async runChild(item) {
            const currentDate = moment(new Date()).format("YYYY-MM")
            const currentMonth = parseInt(currentDate.substr(5, 6), 10)
            const currentYear = parseInt(currentDate.substr(0, 4), 10)

            let payload = {
                filter: { conditions: [] },
                items: [
                    {
                        ...item,
                        processing_month: currentMonth,
                        processing_year: currentYear,
                        processing_status: 'NEW'
                    }
                ]
            }

            try {
                const res = await this.$http.post(`${this.$ipIntegration}erp-integration-run/mass-create`, payload)
                if (res) {
                    this.getIntegrationStatus()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        isSelectedChild(item) {
            return !!this.selectedChilds.find(i => i.id_accounting_closing_type === item.id_accounting_closing_type)
        },

        isRunningChild(item) {
            return !!this.runningChilds.find(i => i.id_accounting_closing_type === item.id_accounting_closing_type)
        },

        async getIntegrationStatus() {
            let payload = {
                showAccountingClosing: true,
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_erp_integration",
                            operator: "IN",
                            value: this.apiResult.items.map(i => i.id_erp_integration)
                        },
                        {
                            AndOr: "AND",
                            column: "processing_status",
                            operator: "IN",
                            value: ['"WIP"', '"NEW"']
                        },
                        {
                            AndOr: "AND",
                            column: "from_erp_to_erp",
                            operator: "=",
                            value: "TO_ERP"
                        }
                    ]
                }
            }

            try {
                const res = await this.$http.post(`${this.$ipIntegration}erp-integration-run/list`, { ...payload })
                if (res) {
                    if (res.data.rows.length) {
                        res.data.rows.forEach(row => {
                            if (!row.id_accounting_closing_type) {
                                let foundFather = this.runningFathers.find(i => i.id_erp_integration === row.id_erp_integration)
                                if (!foundFather) {
                                    let father = this.apiResult.items.find(i => i.id_erp_integration === row.id_erp_integration)
                                    this.runningFathers.push(father)
                                    father.childs.forEach(child => {
                                        let foundChild = this.runningChilds.find(i => i.id_accounting_closing_type === child.id_accounting_closing_type)
                                        if (!foundChild) {
                                            this.runningChilds.push(child)
                                        }
                                    })
                                }
                            } else {
                                let foundChild = this.runningChilds.find(i => i.id_accounting_closing_type === row.id_accounting_closing_type)
                                if (!foundChild) {
                                    this.runningChilds.push(row)
                                }
                            }
                        })

                        this.disableRunIntegrations = true
                        this.runningIntegrations = true

                        clearTimeout(this.timeout)
                        this.timeout = setTimeout(() => {
                            this.getIntegrationStatus()
                        }, 5000)
                    } else {
                        this.runningChilds = []
                        this.runningFathers = []
                        this.disableRunIntegrations = false
                        this.runningIntegrations = false
                        clearTimeout(this.timeout)
                    }
                }
            } catch (err) {
                this.disableRunIntegrations = true
                this.runningIntegrations = false
                clearTimeout(this.timeout)
                this.$fnError(err)
            }
        },

        async runIntegrations() {
            const currentDate = moment(new Date()).format("YYYY-MM")
            const currentMonth = parseInt(currentDate.substr(5, 6), 10)
            const currentYear = parseInt(currentDate.substr(0, 4), 10)

            let fathersToRun = this.selectedFathers.map(father => {
                return {
                    processing_month: currentMonth,
                    processing_year: currentYear,
                    id_erp_integration: father.id_erp_integration,
                    processing_status: 'NEW',
                    id_accounting_closing_type: null
                }
            })

            let fathersIds = fathersToRun.map(father => father.id_erp_integration)
            let childsToRun = []

            this.selectedChilds.forEach(child => {
                if (!fathersIds.includes(child.id_erp_integration)) {
                    childsToRun.push({
                        ...child,
                        processing_month: currentMonth,
                        processing_year: currentYear,
                        processing_status: 'NEW',
                    })
                }
            })

            let items = [...fathersToRun, ...childsToRun]

            let payload = {
                filter: { conditions: [] },
                items
            }

            try {
                const res = await this.$http.post(`${this.$ipIntegration}erp-integration-run/mass-create`, payload)
                if (res) {
                    this.getIntegrationStatus()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        getItemKey() {
            return 'id_' + this.endpoint[1].replaceAll('-', '_')
        },

        refresh() {
            this.backupPage = this.pagination.page

            this.noData = true
            this.apiResult = {
                items: [],
                headers: [],
                columns: [],
            }

            this.load()
        },

        async load() {
            this.loading = true
            let payload = this.setPayload()

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/list', payload)

                this.apiResult = {
                    columns: res.data.columns,
                    headers: this.setHeaders(res.data.headers, res.data.columns),
                    items: this.setItems(res.data.rows, res.data.columns)
                }
                
                // Status
                // this.apiResult.headers.unshift({ text: '', value: 'status', sortable: false })

                // Actions
                this.apiResult.headers.push({ text: '', value: 'actions', sortable: false })
                
                // Expand
                this.apiResult.headers.unshift({ text: '', value: 'checkbox', sortable: false })

                if (!this.apiResult.items.length) {
                    this.noDataMessage = 'Não há dados disponíveis'
                } else {
                    this.noData = false
                }

                this.pagination.page = this.backupPage
                this.loading = false

                return await this.getIntegrationStatus()
            } catch (err) {
                this.$fnError(err)
            }
        },

        setPayload() {
            return {
                showChilds: true,
                filter: {
                    tableRows: this.tableRows,
                    conditions: [{
                        AndOr: "AND",
                        column: "from_erp_to_erp",
                        operator: "=",
                        value: "TO_ERP"
                    }]
                }
            }
        },

        setItems(items, columns) {
            let dateColumns = columns.filter(c => c.columnType === 'DATE' || c.columnType === 'DATETIME')
            items.forEach(i => {
                dateColumns.forEach(c => {
                    if (i[c.columnAlias]) {
                        i[c.columnAlias] = moment.utc(i[c.columnAlias]).format("YYYY-MM-DD")
                    }
                })
            })
            
            return items
        },

        setHeaders(headers, columns) {
            let filteredHeaders = headers.filter(h => !h.hideColumn)

            return filteredHeaders.map((h, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(h.value)

                if (index === -1) {
                    index = i
                }

                if (h.width !== undefined ) {
                    width = h.width
                } else {
                    width = "1%"
                }

                if (h.type !== undefined) {
                    type = h.type
                } else {
                    if (h.file) {
                        type = 'IMG'
                    } else {
                        if (columns[index]) {
                            type = columns[index].columnType
                        }
                    }
                }

                if (h.rules !== undefined) {
                    rules.push(h.rules)
                } else {
                    let columnIdIndex = index

                    if (h.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(h.columnId)
                    }

                    if (columns[columnIdIndex]) {
                        if (columns[columnIdIndex].nullColumn === 'NO') {
                            rules.push('required')
                        }
                    }
                }

                // if h hide is true table column will show but will not appear at edit dialog
                if (h.hide !== undefined) {
                    hide = h.hide
                } else {
                    if (columns[index]) {
                        if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                            hide = true
                            key = columns[index].key
                        }
                    }
                }
                
                if (h.length !== undefined) {
                    length = h.length
                } else {
                    if (columns[index]) {
                        length = columns[index].columnLength
                    }
                }
                
                return { ...h, width, type, rules, hide, key, length }
            })
        },

        updatePage (pagination) {
            if (pagination.page !== this.pagination.page) {
                pagination.page = this.pagination.page
            }
            this.pagination = pagination
        },

        closeTable() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },
    },
}
</script>
